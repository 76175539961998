<template>
  <div class="home-wrap">
    <img class="kf-btn" src="../assets/v3/home/pc-kf.png" alt="kf" @click="showFeedbackDialog">
    <div class="home-wrap-card">
      <div class="home-calculate">
        <div class="calculate-banner hidden-sm-and-down wow animate__fadeInLeft">
          <div class="calculate-banner-content">
            <div class="banner-tip">Proses Mudah dan Cepat dengan Keamanan yang Tinggi</div>
            <div class="content-recommend">
              <div class="recommend-item">
                <img src="../assets/v3/common/banner-check.png" alt="success">
                <div>Ketersediaan limit yang dapat dipakai dan dilunasi kapanpun</div>
              </div>
              <div class="recommend-item">
                <img src="../assets/v3/common/banner-check.png" alt="success">
                <div>Pengajuan mudah & pembayaran fleksibel</div>
              </div>
              <div class="recommend-item">
                <img src="../assets/v3/common/banner-check.png" alt="success">
                <div>Kenaikan limit kredit, potongan terus-menerus</div>
              </div>
            </div>
            <div class="calculate-down">
              <img class="wow animate__bounceInUp android-img" @click="downApp('android')" src="../assets/v3/home/google-pay.png" alt="google" />
              <img class="wow animate__bounceInUp ios-img" @click="downApp('ios')" src="../assets/v3/home/ios-app.png" alt="ios" />
              <img class="wow animate__bounceInUp ojk-afpi-img" src="../assets/v3/home/new-afpi.png" />
            </div>
          </div>
        </div>
        <div class="calculate-card wow animate__fadeInRight">
          <div class="calculate-content-tip-box">
            <div class="m-banner-tip hidden-md-and-up">Proses Mudah dan Cepat</div>
            <div class="m-banner-tip-content hidden-md-and-up">dengan Keamanan yang Tinggi</div>
          </div>
          <img src="../assets/v3/home/home-logo.png" class="wow animate__bounceInRight hidden-xs-only calculate-logo">
          <img src="../assets/v3/home/m-home-logo.png" class="wow animate__bounceInRight hidden-sm-and-up m-calculate-logo">
          <div class="calculate-content">
            <div class="calculate-amount-box">
              <div class="amount-tip">Jumlah Pinjaman</div>
              <div class="main-amount">
                <span>Rp</span>
                <animate-number
                  from="0"
                  :to="amount"
                  :comma="true"
                  :key="amount"
                  duration="500"
                  easing="easeOutQuad"
                  :formatter="formatter"
                  class="amount" />
            </div>
            </div>
            <div class="calculate-slider-box">
              <el-slider
                class="calculate-slider"
                :show-tooltip="false"
                v-model="sliderValue"
                :min="1000000"
                :max="50000000"
                :step="1000000"
                @input="changeSlider">
              </el-slider>
              <div class="slider-quota">
                <div>1.000.000</div>
                <div>50.000.000</div>
              </div>
            </div>
            <div class="calculate-detail-box">
              <div class="detail-left">
                <div class="detail-tip">Lama Pinjaman</div>
                <div class="period">90 hari</div>
              </div>
              <div class="detail-right">
                <div class="detail-tip">Bunga Per Hari</div>
                <div class="rate">0.3%</div>
              </div>
            </div>
            <div class="calculate-bottom-tip">*Proses Pinjaman Cepat</div>
            <div class="calculate-btn-box">
              <div class="calculate-btn" @click="goLoan">
                <img src="../assets/v3/home/loan.png">
                <span>Lihat Limit Saya</span>
              </div>
            </div>
            <div class="m-ojk-afpi-box hidden-md-and-up">
              <img src="../assets/v3/home/new-m-afpi.png" class="m-ojk-afpi">
            </div>
          </div>
          <div class="content-recommend hidden-md-and-up">
            <div class="m-calculate-down wow animate__fadeInUp">
              <img src="../assets/v3/home/google-pay.png" class="m-google" alt="google" @click="downApp('android')" />
              <img src="../assets/v3/home/ios-app.png" class="m-ios" alt="ios" @click="downApp('ios')" />
            </div>
              <div class="recommend-item wow animate__fadeInLeft">
                <img src="../assets/v3/common/banner-check.png" alt="success">
                <div>Ketersediaan limit yang dapat dipakai dan dilunasi kapanpun</div>
              </div>
              <div class="recommend-item wow animate__fadeInLeft">
                <img src="../assets/v3/common/banner-check.png" alt="success">
                <div>Pengajuan mudah & pembayaran fleksibel</div>
              </div>
              <div class="recommend-item wow animate__fadeInLeft">
                <img src="../assets/v3/common/banner-check.png" alt="success">
                <div>Kenaikan limit kredit, potongan terus-menerus</div>
              </div>
            </div>
        </div>
    </div>
    </div>
    <div class="home-serve wow animate__fadeInUp">
      <div class="serve-tip">
        Bantusaku bisa menyediakan layanan apa saja?
        <img src="../assets/v3/home/change-this.png" alt="serve">
      </div>
      <div class="serve-item-box">
        <el-row :gutter="0">
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
            <div class="grid-content wow animate__fadeInUp">
              <div class="item-card">
                <img src="../assets/v3/home/serve-item2.png" alt="lock"/>
                <div>Bantu Belanja</div>
              </div>
              <div class="grid-bottom-tip">Membeli kebutuhan bulanan tanpa pusing dengan BantuSaku.</div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
            <div class="grid-content wow animate__fadeInUp">
              <div class="item-card">
                <img src="../assets/v3/home/serve-item3.png" alt="lock"/>
                <div>Bantu Liburan</div>
              </div>
              <div class="grid-bottom-tip">Memenuhi keinginan staycation dan jalan-jalanmu dengan BantuSaku.</div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
            <div class="grid-content wow animate__fadeInUp">
              <div class="item-card">
                <img src="../assets/v3/home/serve-item4.png" alt="lock"/>
                <div>Bantu Usaha</div>
              </div>
              <div class="grid-bottom-tip">Mantapkan keinginan berbisnismu sekarang dengan tambahan modal dari BantuSaku.</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="business-wrap">
  <div class="business-data wow animate__fadeInLeft">
    <div class="business-item-box">
      <el-row :gutter="0">
        <!-- Borrower segment -->
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
          <div class="segment-title"><img src="../assets/v3/home/ojk-data-1.png" alt="borrower"/></div>
          <div class="business-grid-content">
            <div class="num">1.79</div>
            <div class="unit">Juta</div>
            <div class="data">Jumlah Penerima Dana Sejak Awal Berdiri</div>
            <div class="bottom-line"></div>
          </div>
          <div class="business-grid-content">
            <div class="num">168</div>
            <div class="unit">Ribu</div>
            <div class="data">Jumlah Penerima Dana Tahun Berjalan</div>
            <div class="bottom-line"></div>
          </div>
          <div class="business-grid-content">
            <div class="num">26.9</div>
            <div class="unit">Ribu</div>
            <div class="data">Jumlah Penerima Dana di Posisi Akhir</div>
            <div class="bottom-line"></div>
          </div>
        </el-col>
        <!-- Lender segment -->
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
          <div class="segment-title"><img src="../assets/v3/home/ojk-data-2.png" alt="lender"/></div>
          <div class="business-grid-content">
            <div class="num">4</div>
            <div class="unit"></div>
            <div class="data">Jumlah Pemberi Dana Sejak Awal Berdiri</div>
            <div class="bottom-line"></div>
          </div>
          <div class="business-grid-content">
            <div class="num">4</div>
            <div class="unit"></div>
            <div class="data">Jumlah Pemberi Dana Tahun Berjalan</div>
            <div class="bottom-line"></div>
          </div>
          <div class="business-grid-content">
            <div class="num">4</div>
            <div class="unit"></div>
            <div class="data">Jumlah Pemberi Dana di Posisi Akhir</div>
            <div class="bottom-line"></div>
          </div>
        </el-col>
        <!-- Fund segment -->
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
          <div class="segment-title"><img src="../assets/v3/home/ojk-data-3.png" alt="fund"/></div>
          <div class="business-grid-content">
            <div class="num">17.80</div>
            <div class="unit">Triliun</div>
            <div class="data">Jumlah Pendanaan yang Tersalurkan Sejak Awal Berdiri</div>
            <div class="bottom-line"></div>
          </div>
          <div class="business-grid-content">
            <div class="num">1.50</div>
            <div class="unit">Triliun</div>
            <div class="data">Jumlah Pendanaan yang Tersalurkan Tahun Berjalan</div>
            <div class="bottom-line"></div>
          </div>
          <div class="business-grid-content">
            <div class="num">119</div>
            <div class="unit">Miliar</div>
            <div class="data">Jumlah Pendanaan yang Tersalurkan di Posisi Akhir</div>
            <div class="bottom-line"></div>
          </div>
          <div class="business-grid-content">
            <div class="num">775</div>
            <div class="unit">Miliar</div>
            <div class="data">Jumlah Pendanaan yang Belum Dibayar</div>
            <div class="bottom-line"></div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</div>
    <div class="tutorial wow animate__fadeInUp hidden-md-and-down">
      <div class="tutorial-item-box wow animate__pulse">
        <el-row :gutter="0">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="tutorial-grid-content">
              <img class="tutorial-left" src="../assets/v3/home/tutorial1.png" alt="tutorial1">
              <div class="tutorial-right">
                <img class="right-tip" src="../assets/v3/home/tutorial5.png" alt="" />
                <div class="right-top">Proses Pengajuan Cepat dan Mudah</div>
                <div class="right-text">Pengajuan Pinjaman Dana yang Cepat dan Mudah hanya dalam satu genggaman Smartphone. Hanya menggunakan KTP dan Rekening Pribadi.</div>
              </div>
            </div>  
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="tutorial-grid-content">
              <img class="tutorial-left" src="../assets/v3/home/tutorial2.png" alt="tutorial1">
              <div class="tutorial-right bg-darks">
                <img class="right-tip" src="../assets/v3/home/tutorial6.png" alt="" />
                <div class="right-top">Jumlah Produk Variatif</div>
                <div class="right-text">Variatif produk yang disediakan oleh BantuSaku sangat membantu para calon peminjam, memilih jumlah produk pinjaman sesuai dengan kebutuhan mereka.</div>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="tutorial-grid-content">
              <img class="tutorial-left" src="../assets/v3/home/tutorial3.png" alt="tutorial1">
              <div class="tutorial-right bg-darks">
                <img class="right-tip" src="../assets/v3/home/tutorial7.png" alt="" />
                <div class="right-top">Waktu Pembayaran Pinjaman Fleksibel</div>
                <div class="right-text">Tenor Panjang & Sangat fleksibel Total hingga 90 hari waktu pengembalian pinjaman.</div>
              </div> 
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="tutorial-grid-content">
              <img class="tutorial-left" src="../assets/v3/home/tutorial4.png" alt="tutorial1">
              <div class="tutorial-right">
                <img class="right-tip" src="../assets/v3/home/tutorial8.png" alt="" />
                <div class="right-top">Aman dan Terpercaya</div>
                <div class="right-text">Aplikasi BantuSaku dilengkapi dengan teknologi dan sistem manajemen resiko canggih yang memanfaatkan teknologi, algoritma, dan sumber data yang eksklusif.</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="m-tutorial-wrap hidden-md-and-up">
          <div class="m-tutorial-item-box wow animate__pulse">
            <div class="m-tutorial-card" v-if="activeTeam == 1">
                <img class="tutorial-left" src="../assets/v3/home/tutorial1.png" alt="tutorial1">
                <div class="tutorial-right">
                  <img class="right-tip" src="../assets/v3/home/tutorial5.png" alt="" />
                  <div class="right-top">Proses Pengajuan Cepat dan Mudah</div>
                  <div class="right-text">Pengajuan Pinjaman Dana yang Cepat dan Mudah hanya dalam satu genggaman Smartphone. Hanya menggunakan KTP dan Rekening Pribadi.</div>
                </div>
            </div>
            <div class="m-tutorial-card" v-if="activeTeam == 2">
                <img class="tutorial-left" src="../assets/v3/home/tutorial2.png" alt="tutorial1">
                <div class="tutorial-right">
                  <img class="right-tip" src="../assets/v3/home/tutorial6.png" alt="" />
                  <div class="right-top">Jumlah Produk Variatif</div>
                  <div class="right-text">Variatif produk yang disediakan oleh BantuSaku sangat membantu para calon peminjam, memilih jumlah produk pinjaman sesuai dengan kebutuhan mereka.</div>
                </div>
            </div>
            <div class="m-tutorial-card" v-if="activeTeam == 3">
                <img class="tutorial-left" src="../assets/v3/home/tutorial3.png" alt="tutorial1">
                <div class="tutorial-right">
                  <img class="right-tip" src="../assets/v3/home/tutorial7.png" alt="" />
                  <div class="right-top">Waktu Pembayaran Pinjaman Fleksibel</div>
                  <div class="right-text">Tenor Panjang & Sangat fleksibel Total hingga 60 hari waktu pengembalian pinjaman.</div>
                </div>
            </div>
            <div class="m-tutorial-card" v-if="activeTeam == 4">
                <img class="tutorial-left" src="../assets/v3/home/tutorial4.png" alt="tutorial1">
                <div class="tutorial-right">
                  <img class="right-tip" src="../assets/v3/home/tutorial8.png" alt="" />
                  <div class="right-top">Aman dan Terpercaya</div>
                  <div class="right-text">Aplikasi BantuSaku dilengkapi dengan teknologi dan sistem manajemen resiko canggih yang memanfaatkan teknologi, algoritme, dan sumber data yang eksklusif.</div>
                </div>
            </div>
          </div>
          <div class="m-tutorial-btn-box">
            <img src="../assets/v3/home/previous.png" alt="previous" @click="previousTutorial()">
            <img src="../assets/v3/home/next.png" alt="next" @click="nextTutorial()">
          </div>
    </div>
    <div class="m-payment-wrap hidden-md-and-up">
      <div class="m-payment-title wow animate__pulse">Limit paling tinggi</div>
      <div class="m-payment-rp wow animate__pulse">Rp50.000.000</div>
      <img src="../assets/v3/home/m-rp-logo.png" alt="rp-logo" class="rp-logo wow animate__fadeInLeft">
      <div class="m-payment-content">
        <div class="m-payment-card wow animate__fadeInLeft">
          <div class="m-payment-title-box">
            <img src="../assets/v3/home/home-rp.png">
            <div>Informasi Pinjaman Anda</div>
          </div>
          <div class="content">
            <div class="content-rp">
              <div class="rp-title">Pokok Pinjaman</div>
              <div class="rp-info">Rp 1.000.000</div>
            </div>
            <div class="content-img">
              <img src="../assets/v3/home/home-card-item1.png">
            </div>
          </div>
          <div class="pinjaman-left-info-box">
              <div class="pinjaman-info">
                <div class="pinjaman-info-top">0.3%</div>
                <div class="pinjaman-info-bottom">Bunga Per Hari</div>
              </div>
              <div class="pinjaman-info pinjaman-info-right">
                <div class="pinjaman-info-top">90 Hari</div>
                <div class="pinjaman-info-bottom">Jangka Waktu Angsuran</div>
              </div>
          </div>
          <img src="../assets/v3/home/line.png" alt="line" class="line">
        </div>
        <div class="m-payment-card wow animate__fadeInRight">
          <div class="m-payment-title-box">
            <img src="../assets/v3/home/home-card.png">
            <div>Informasi Angsuran Anda</div>
          </div>
          <div class="pinjaman-left-info-box">
              <div class="pinjaman-info">
                <div class="pinjaman-info-top">Rp 270.000</div>
                <div class="pinjaman-info-bottom">Total Bunga</div>
              </div>
              <div class="pinjaman-info pinjaman-info-right">
                <div class="pinjaman-info-top">Rp 1.270.000</div>
                <div class="pinjaman-info-bottom">Total Dibayarkan</div>
              </div>
          </div>
          <div class="bottom-img">
            <img src="../assets/v3/home/home-card-item3.png">
          </div>
        </div>
      </div>

    </div>
    <div class="payment-wrap hidden-md-and-down">
      <div class="payment-box">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="wow animate__bounceInLeft rp-box">
              <div class="rp-title">Limit paling tinggi</div>
              <div class="rp-number">Rp50.000.000</div>
              <img src="../assets/v3/home/rp-logo.png" class="rp-logo">
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
            <div class="wow animate__bounceInRight pinjaman-box">
              <div class="pinjaman-left-box">
                <div class="pinjaman-left-title-box">
                  <img src="../assets/v3/home/home-rp.png" class="pinjaman-rp-img">
                  <div class="pinjaman-left-title">Informasi Pinjaman Anda</div>
                </div>
                <div class="pinjaman-left-rp-box">
                  <div class="pinjaman-left-rp-title">Pokok Pinjaman</div>
                  <div class="pinjaman-left-rp">Rp 1.000.000</div>
                </div>
                <div class="pinjaman-left-info-box">
                  <div class="pinjaman-info">
                    <div class="pinjaman-info-top">0.3%</div>
                    <div class="pinjaman-info-bottom">Bunga Per Hari</div>
                  </div>
                  <div class="pinjaman-info pinjaman-info-right">
                    <div class="pinjaman-info-top">90 Hari</div>
                    <div class="pinjaman-info-bottom">Jangka Waktu Angsuran</div>
                  </div>
                </div>
              </div>
              <div class="pinjaman-right-box">
                <img src="../assets/v3/home/home-card-item1.png">
              </div>
            </div>
            <div class="wow animate__bounceInRight pinjaman-box pinjaman-secord">
              <div class="pinjaman-left-box">
                <div class="pinjaman-left-title-box">
                  <img src="../assets/v3/home/home-card.png" class="pinjaman-rp-img">
                  <div class="pinjaman-left-title">Informasi Angsuran Anda</div>
                </div>
                <div class="pinjaman-left-info-box">
                  <div class="pinjaman-info">
                    <div class="pinjaman-info-top">Rp 270.000</div>
                    <div class="pinjaman-info-bottom">Total Bunga</div>
                  </div>
                  <div class="pinjaman-info pinjaman-info-right">
                    <div class="pinjaman-info-top">Rp 1.270.000</div>
                    <div class="pinjaman-info-bottom">Total Dibayarkan</div>
                  </div>
                </div>
              </div>
              <div class="pinjaman-bottom-box">
                <img src="../assets/v3/home/home-card-item3.png">
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="feedback-wrap wow animate__fadeInUp">
      <div class="feedback-card">
        <el-carousel class="feedback-carousel" indicator-position="none">
          <el-carousel-item>
            <div class="feedback-text">"Aplikasi yang sederhana dan praktis, terima kasih BantuSaku telah memberi kesempatan saya untuk ngajuin pinjaman, berharap semoga di setujui. Kalo di setujui,saya akan tetap menjaga kepercayaan yg di berikan pada saya dengan cara selalu komit untuk bayar tepat waktu. Semoga BantuSaku semakin sukses,amin"</div>
            <div class="feedback-prople">- Oni Budiharto -</div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="feedback-text">"Saya sangat membutuhkan pinjaman, dan Play Store memberi saya beberapa dan ketika saya sampai di sini saya membaca beberapa peringkat dan komentar, yang membujuk saya untuk mencobanya, dan dapatkah Anda percaya bahwa dalam waktu kurang dari 5 menit Saya mendapatkan pinjaman saya, cepat dan mudah, tanpa terlalu banyak cerita ... sederhana dan efisien, Anda pantas mendapatkan peringkat bintang lima ini"</div>
            <div class="feedback-prople">- yoshi suzanne Christian -</div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="feedback-text">"Trima kasih prifikasih antas nama Ika sartika sudah gagal, saya hapus ya aplikasinya, tolong di jaga baik2 data saya, saya tidak jadi minjm alhmdulilah sudah dapat dari sodara, ttp saya kasih bintang 🌟🌟🌟🌟🌟, karna ngisi datanya gampang, sukses selalu".</div>
            <div class="feedback-prople">- yoshi suzanne Christian -</div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="feedback-bottom">
        <img src="../assets/v3/home/start.png" alt="star">
        <div>Ulasan Pelanggan Kami</div>
      </div>
    </div>
    <div class="lender-wrap hidden-xs-only wow animate__fadeInUp">
      <div class="lender-card">
        <img class="rp-img" src="../assets/v3/home/rp.png" alt="rp">
        <div class="lender-center">
          <img src="../assets/v3/home/sm-google.png" alt="sm-google">
          <img src="../assets/v3/home/sm-ios.png" alt="sm-ios" class="sm-ios">
          <div class="center-t">BANTUSAKU LENDER</div>
          <div class="center-c">Kelola keuanganmu dengan cara teratur</div>
          <div class="center-b">
            <div>Mudah</div>
            <div>Cepat</div>
            <div>Terjangkau</div>
          </div>
        </div>
        <div class="lender-right">
          <div>Jaminan Uang Kembali Dalam 5 Bulan</div>
          <div class="lender-btn" @click="goLender">Lihat Detail  </div>
        </div>
      </div>
    </div>
    <div class="m-lender-wrap hidden-sm-and-up">
      <div class="m-lender-card">
          <div class="m-lender-title">BANTUSAKU LENDER</div>
          <div class="m-lender-tip">Kelola keuanganmu dengan cara teratur</div>
          <div class="m-lender-center">
            <div>Mudah</div>
            <div>Cepat</div>
            <div>Terjangkau</div>
          </div>
          <div class="m-lender-bottom">Jaminan Uang Kembali Dalam 5 Bulan</div>
          <div class="m-lender-btn" @click="goLender">Lihat Detail</div>
      </div>
    </div>
    <div class="partner-wrap wow animate__fadeInUp">
      <div class="partner-tip">Mitra Kami</div>
      <div class="partner-center">
        <el-carousel class="partner-silder" height="156px">
          <el-carousel-item class="partner-silder-item">
            <div class="partner">
              <img src="../assets/v3/home/newpartner1.png" alt="partner">
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="partner">
              <img src="../assets/v3/home/newpartner2.png" alt="partner">
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="partner">
              <img src="../assets/v3/home/newpartner3.png" alt="partner">
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="partner">
              <img src="../assets/v3/home/newpartner4.png" alt="partner">
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="auth-wrap hidden-xs-only wow animate__fadeInUp">
      <div class="auth-card">
        <el-row :gutter="0">
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="auth-content">
              <div>Berizin & Diawasi Oleh :</div>
              <div class="image-wrapper">
                <img src="../assets/v3/home/ojk-logo.png" alt="ojk" />
              </div>
              <!--I want this also have grayish border radius background-->
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="auth-content">
              <div>Terdaftar di :</div>
              <div class="img-container">
                <img src="../assets/v3/home/newvkominfo.png" alt="kominfo" style="height: 4em; width: 4em;"/>
                <img src="../assets/v3/home/afpi.png" alt="afpi" style="height: 4em; width: 10em;"/>
                <img src="../assets/v3/home/newvlaps.png" alt="laps" style="height: 4em; width: 8em;"/>
              </div>
              <!-- <div class="cek-text" @click="check('afpi')">Cek Disini</div> -->
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="auth-content">
              <div>Information Security Management System :</div>
              <img src="../assets/v3/home/kan.png" alt="cb" class="kan" />
              <!-- <div class="cek-text" @click="check('cb')">Cek Disini</div> -->
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="m-auth-wrap hidden-sm-and-up">
      <div class="m-auth-item">
        <div class="m-auth-left">
          <div class="m-auth-title">Berizin & Diawasi Oleh :</div>
        </div>
        <div class="m-auth-right">
          <img src="../assets/v3/home/ojk-logo.png" alt="m-ojk" class="m-ojk" />
        </div>
      </div>
      <div class="m-auth-item">
        <div class="m-auth-left">
          <div class="m-auth-title">Terdaftar di :</div>
          <!-- <div class="m-auth-text"  @click="check('afpi')">（Cek Disini）</div> -->
        </div>
        <div class="m-auth-right">
          <div class="m-img-container">
            <img src="../assets/v3/home/newvkominfo.png" alt="m-afpi" />
            <img src="../assets/v3/home/m-afpi.png" alt="m-afpi" />
            <img src="../assets/v3/home/newvlaps.png" alt="m-afpi" />
          </div>
        </div>
      </div>
      <div class="m-auth-item">
        <div class="m-auth-left">
          <div class="m-auth-title">
            Information Security Management System :
          </div>
          <!-- <div class="m-auth-text"  @click="check('cb')">（Cek Disini）</div> -->
        </div>
        <div class="m-auth-right">
          <img src="../assets/v3/home/kan.png" alt="m-cb" class="m-kan" />
        </div>
      </div>
    </div>

    <safetyDialog 
      ref="pcSafety"
      v-model="showPcSafety"/>
    <mSafetyDialog 
      ref="mSafet"
      v-model="showMSafety"/>
    <feedbackDialog 
      ref="feedbackDialog"
      v-model="showPcFeedback"/>
      <mfeedbackDialog 
      ref="mfeedbackDialog"
      v-model="showMFeedback"/>
    <downApp 
      ref="downApp"
      v-model="showDownApp"
      @closeDownDialog="closeDownDialog"/>
  </div>
</template>

<script>
import { isPC } from '../utils/auth'
import safetyDialog from '../components/safety-dialog'
import mSafetyDialog from '../components/m-safety-dialog'
import feedbackDialog from '../components/feedback-dialog'
import mfeedbackDialog from '../components/m-feedback-dialog'
import downApp from '../components/down-app'
import {WOW} from 'wowjs'
export default {
  name: 'Home',
  components: {
    safetyDialog, mSafetyDialog, feedbackDialog,mfeedbackDialog,downApp
  },
  data(){
    return{
      isPC: true,
      activeTeam: 1,
      amount: 1000000,
      sliderValue: 20000000,
      showPcSafety: false,
      showMSafety: false,
      showPcFeedback: false,
      showMFeedback: false,
      showDownApp: false
    }
  },
  mounted(){
    this.$nextTick(() => {
      var wow = new WOW({
        animateClass: 'animated',
        offset: 0,
      })
      wow.init()
    })
    this.showSafety()
  },
  methods:{
    previousTutorial(){
      if(this.activeTeam == 1){
        this.activeTeam = 4
      } else{
        this.activeTeam = this.activeTeam - 1
      }
    },
    nextTutorial(){
      if(this.activeTeam < 4){
        this.activeTeam = this.activeTeam + 1
      } else{
        this.activeTeam = 1
      }
    },
    closeDownDialog(){
      this.showDownApp = false
      this.showMSafety = true
    },
    changeSlider(e){
      this.amount = e
    },
    formatter(num){
      return num.toLocaleString().replace(/,/g,".")
    },
    goLoan(){
      location.href = "https://m.bantusaku.id"
    },
    goLender(){
      sessionStorage.setItem('activeTab', '2')
      this.$router.push('/lender')
    },
    downApp(type){
      if(type == 'ios'){
        window.open('https://apps.apple.com/id/app/bantusaku-pinjaman-online-ojk/id1619483828')
      } else {
        window.open('https://play.google.com/store/apps/details?id=com.smartec.ft')
      }
    },
    showFeedbackDialog(){
      if (isPC() == false) {
          this.showMFeedback = true
        } else {
          this.showPcFeedback = true
        }
    },
    check(type){
      if(type == 'ojk'){
        window.open('https://drive.google.com/file/d/1WGs3a1CsLSixNC86EUB6isFB2BZlP3sZ/view?usp=drive_link')
      } else if(type == 'afpi') {
        window.open('https://drive.google.com/file/d/1o3oLbprXkB8boVNRH2kyK9Zc0ddp3dAs/view?usp=drive_link')
      } else {
        window.open('https://drive.google.com/file/d/1kbSXtHpUe15aYNJuEGV4Flp4FdIPqLBw/view?usp=drive_link')
      }
    },
    showSafety(){
      sessionStorage.setItem('activeTab', '1')
      if(!sessionStorage.getItem('safety')){
        sessionStorage.setItem('safety',true)
        if (isPC() == false) {
          this.isPC = false
          this.showDownApp = true
        } else {
          this.isPC = true
          this.showPcSafety = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home-wrap{
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  font-family: "Poppins-Regular";
  .kf-btn{
      width: 92px;
      height: 58px;
      position: fixed;
      right: 0;
      bottom: 20%;
      cursor: pointer;
      z-index: 99;
  }
  .home-wrap-card{
    width: 100%;
    background: #C6E8ED;
  }
  .m-ojk-afpi-box{
    width: 100%;
    text-align: center;
    padding-top: 15px;
    .m-ojk-afpi{
      width: 118px;
      height: 30px;
    }
  }
  .home-calculate{
    max-width: 1400px;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 0 0 40px 0;
    .calculate-banner{
      width: 42%;
      height: 100%;
      display: flex;
      .calculate-banner-content{
        margin-top: 18%;
        margin-bottom: 90px;
        .banner-content-tip{
          width: 100%;
        }
        .banner-tip{
          font-size: 44px;
          width: 100%;
          color: #1D1D1D;
          font-weight: 700;
          span{
            color: #B3E872;
          }
        }
      }
    }
    .calculate-card{
      width: 58%;
      height: 100%;
      padding-left: 6%;
      position: relative;
      padding-bottom: 10px;
      .calculate-logo{
        width: 322px;
        position: absolute;
        bottom: -40px;
        right: 0;
        z-index: 10;
      }
      .m-calculate-logo{
        width: 161px;
        position: absolute;
        right: 40px;
        top: 66px;
        z-index: 10;
      }
      .calculate-content-tip-box{
        padding-top: 20px;
      }
      .calculate-content-tip{
        width: 80%;
      }
      .m-banner-tip{
        width: 252px;
        font-size: 22px;
        color: #1D1D1D;
        font-weight: 600;
        padding-left: 28px;
      }
      .m-banner-tip-content{
        width: 200px;
        font-size: 18px;
        color: #145246;
        font-weight: 600;
        padding-left: 28px;
      }
      .calculate-content{
        width: 80%;
        min-height: 504px;
        background-color: #FAFAFA;
        border-radius: 22px;
        padding: 40px 70px 0 70px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        margin-top: 12%;
        .calculate-amount-box{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          .amount-tip{
            font-size: 16px;
            font-weight: 600;
          }
          .main-amount{
            font-size: 30px;
            color: #084032;
            font-weight: 600;
          }
        }
        .calculate-slider-box{
          width: 100%;
          padding: 0 5% 0 4%;
          box-sizing: border-box;
          margin: 0px 0 14px 0;
          .slider-quota{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #B4B4B4;
            font-size: 20px;
            font-weight: 600;
          }
        }
        .calculate-detail-box{
          width: 100%;
          background: #FAFAFA ;
          border-radius: 12px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding-top: 40px;
          .detail-left, .detail-right{
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .detail-tip{
              font-size: 16px;
              font-weight: 600  ;
              color: #B4B4B4;
              margin-bottom: 18px;
            }
            .period, .rate{
              width: 80%;
              padding: 8px 0;
              background: #F4F4F4;
              border-radius: 40px;
              border: 1px solid #145246;
              text-align: center;
              color: #041410;
              font-size: 20px;
            }
          }
        }
        .calculate-bottom-tip{
          width: 100%;
          text-align: center;
          margin: 38px 0 30px 0;
          color: #145246;
          font-size: 16px;
        }
        .calculate-btn-box{
          width: 100%;
          background: #FAFAFA;
          display: flex;
          align-items: center;
          justify-content: center;
          .calculate-btn{
            width: 76%;
            height: 60px;
            font-size: 18px;
            font-weight: 600;
            background: #145246;
            color: #fff;
            border-radius: 30px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            animation: btnSize 2s infinite;
            animation-delay: 1s;
            img{
              width: 20px;
              margin-right: 5px;
            }
          }
          @keyframes btnSize {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.1);
            }
            100% {
              transform: scale(1);
            }
          }
        }
      }
    }
  }
  .content-recommend{
      width: 100%;
      margin: 58px 0 72px 0;
      .recommend-item{
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        img{
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        div{
          font-size: 20px;
          color: #145246;
          line-height: 24px;
        }
      }
  }
  .calculate-down{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .android-img{
      width: 158px;
      margin-right: 20px;
      cursor: pointer;
      height: 48px;
    }
    .ios-img{
      width: 158px;
      margin-right: 20px;
      cursor: pointer;
      height: 48px;
    }
    .ojk-afpi-img{
      width: 189px;
      cursor: pointer;
      height: 51px;
    }
  }
  .m-calculate-down{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 18px;
    .m-google, .m-ios{
      width: 125px;
      height: 37px;
    }
  }
  .home-serve{
    max-width: 1400px;
    margin: 0 auto;
    background: #ffffff;
    position: relative;
    padding: 60px 0 80px 0;
    box-sizing: border-box;

    // .el-col{
    //  padding: 0 50px !important; 
    // }
    .serve-tip{
      width: 60%;
      font-size: 44px;
      color: #1D1D1D;
      font-weight: 600;
      position: relative;
      img{
        width: 86px;
        position: absolute;
        right: 36%;
        bottom: -10%;

      }
    }
    .serve-item-box{
      .grid-content{
        .item-card{
          width: 406px;
          height: 120px;
          background: #ACDDB1;
          border-radius: 79px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          font-size: 24px;
          font-weight: bold;
          line-height: 40px;
          margin: 70px 0 20px 0;
          img{
            width: 82px;
            margin-right: 10px;
            // margin-right: 30px;
          }
        }
        .grid-bottom-tip{
          width: 100%;
          color: #28543F;
          font-size: 20px;
        }
      }
    }
  }
  .business-wrap{
  width: 100%;
  background: #C6E8ED;
}
.business-data{
  padding-bottom: 60px;
  background: #C6E8ED;
  position: relative;
  .business-item-box{
    max-width: 1400px;
    margin: 0 auto;
    .segment-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 30px;
      text-align: center;
      margin-top: 30px;
    }
    .business-grid-content{
      padding: 20px 10px 0 0;
      box-sizing: border-box;
      position: relative;
      margin: 30px auto;
      text-align: center;
      .num, .unit{
        font-size: 38px;
        font-weight: bold;
        line-height: 38px;
        min-height: 38px;
      }
      .data{
        font-size: 14px;
        font-weight: 500;
        margin: 30px 0 28px 0;
      }
      .bottom-line{
        width: 109px;
        height: 4px;
        background: #ACDDB1;
        margin: 0 auto;
      }
    }
  }
  :last-child > .business-grid-content:after{
      height: 0 !important;
  }
}

  .m-tutorial-wrap{
    width: 100%;
    background: #F8F9FC !important;
    padding: 35px 22px 22px 22px !important;
    .m-tutorial-item-box{
      .m-tutorial-card{
        display: flex;
        justify-content: center;
        .tutorial-left{
          width: 166px;
          height: 162px;
        }
        .tutorial-right{
          width: 166px;
          background: url("../assets/v3/home/m-tutorial-bg.png") no-repeat;
          background-size: 100% 100%;
          position: relative;
          color: #1E6642;
          box-sizing: border-box;
          margin-top: 20px;
          padding: 20px 16px 24px 16px;
          .right-tip{
            width: 55px;
            position: absolute;
            left: 34%;
            top: -36px;
          }
          .right-top{
            width: 134px;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 6px;
          }
          .right-text{
            width: 136px;
            font-size: 10px;
            color: #1E6642;
          }
        }
      }
    }
    .m-tutorial-btn-box{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      img{
        width: 26px;
        height: 26px;
        cursor: pointer;
        margin: 0 5px;
      }
    }
  }

  .tutorial{
    width: 100%;
    min-height: 500px;
    background: #FBFFFF;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 70px 0 0 0;
    .tutorial-item-box{
      max-width: 1400px;
      margin: 0 auto;
      .tutorial-grid-content{
        display: flex;
        justify-content: center;
        margin-bottom: 100px;
        .tutorial-left{
          width: 276px;
          height: 269px;
        }
        .tutorial-right{
          width: 276px;
          height: 291px;
          background: #ACDDB1 ;
          border-radius: 8px 8px 100px 8px;
          position: relative;
          margin-top: 30px;
          color: #1E6642;
          padding: 38px 16px 0 22px;
          box-sizing: border-box;
          .right-tip{
            width: 88px;
            height: 88px;
            position: absolute;
            left: 34%;
            top: -58px;
          }
          .right-top{
            width: 230px;
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 10px;
          }
          .right-text{
            width: 186px;
            font-size: 13px;
          }
        }
        .bg-darks{
          background: #ACDDB1;
          color: #1E6642;
        }
      }
    }
  }
  .m-payment-wrap{
    width: 100%;
    min-height: 300px;
    background: #C6E8ED;
    padding: 47px 14px 22px 14px;
    box-sizing: border-box;
    position: relative;
    .m-payment-title{
      font-size: 16px;
      color: #1D1D1D;
      margin-bottom: 10px;
      padding-left: 29px;
    }
    .m-payment-rp{
      font-size: 35px;
      font-weight: bold;
      color: #145246;
      padding-left: 29px;
      margin-bottom: 144px;
      text-shadow: 1px 2px 0px #FFFFFF, inset 0px 1px 2px rgba(255,255,255,0.5);
    }
    .rp-logo{
      width: 144px;
      height: 146px;
      position: absolute;
      top: 150px;
      left: 50%;
      margin-left: -73px;
    }
    .m-payment-content{
      width: 100%;
      border-radius: 16px;
      background: #fff;
      padding: 50px 18px 8px 18px;
      .m-payment-card{
        .m-payment-title-box{
          display: flex;
          align-items: center;
          margin-bottom: 26px;
          img{
            width: 40px;
            height: 40px;
          }
          div{
            color: #1D1D1D;
            font-size: 18px;
            margin-left: 9px;
          }
        }
      }
      .content{
        display: flex;
        align-items: center;
        justify-content: space-around;
        .content-rp{
          .rp-title{
            font-size: 12px;
          }
          .rp-info{
            color: #28543F;
            font-size: 28px;
            font-weight: bold;
          }
        }
        .content-img{
          img{
            width: 59px;
          }
        }
      }
      .pinjaman-left-info-box{
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin: 12px 0 28px 0;
          .pinjaman-info{
            .pinjaman-info-top{
              font-size: 20px;
              font-weight: 600;
              color: #28543F;
            }
            .pinjaman-info-bottom{
              color: #1D1D1D;
              font-size: 12px;
            }
          }
      }
      .line{
        width: 310px;
        height: 1px;
        margin: 0 auto;
      }
      .bottom-img{
        text-align: center;
        img{
          width: 114px;
          height: 86px;
        }
      }
    }
  }
  .payment-wrap{
    width: 100%;
    background: #FAFAFA;
    .payment-box{
      max-width: 1400px;
      margin: 0 auto;
      padding: 44px 0 35px 0;
      box-sizing: border-box;
      .rp-box{
        width: 100%;
        padding: 82px 85px 47px 85px;
        background: #C6E8ED;
        border-radius: 20px;
        .rp-title{
          color: #1D1D1D;
          font-size: 20px;
        }
        .rp-number{
          font-size: 44px;
          font-weight: 600;
          color: #145246;
          font-family: "Poppins-Medium";
          text-shadow: 1px 2px 0px #FFFFFF, inset 0px 1px 3px rgba(255,255,255,0.5);
        }
        .rp-logo{
          width: 318px;
          height: 330px;
          margin-top: 40px;
        }
      }
      .pinjaman-info{
          .pinjaman-info-top{
            font-size: 22px;
            font-weight: 600;
            color: #28543F;
          }
          .pinjaman-info-bottom{
            color: #1D1D1D;
            font-size: 14px;
          }
        }
        .pinjaman-info-right{
          margin-left: 122px;
        }
      .pinjaman-box{
        background: url("../assets/v3/home/pinjaman-bg.png") no-repeat;
        background-size: 100% 100%;
        padding: 30px 55px 40px 60px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        min-height: 324px;
        .pinjaman-left-box{
          .pinjaman-left-title-box{
            display: flex;
            align-items: center;
            .pinjaman-rp-img{
              width: 67px;
            }
            .pinjaman-left-title{
              font-size: 20px;
              color: #1D1D1D;
              font-weight: 600;
              margin-left: 20px;
            }
          }
          .pinjaman-left-rp-box{
            display: flex;
            align-items: center;
            margin: 20px 0;
            .pinjaman-left-rp-title{
              font-size: 14px;
              color: #1D1D1D;
            }
            .pinjaman-left-rp{
              color: #28543F;
              font-size: 40px;
              font-weight: 600;
              margin-left: 32px;
            }
          }
          .pinjaman-left-info-box{
            display: flex;
            align-items: center;
          }
        }
        .pinjaman-right-box{
          img{
            width: 162px;
          }
        }
      }
      .pinjaman-secord{
        background: #FFFFFF;
        border-radius: 20px;
        margin-bottom: 0;
        min-height: 245px;
        position: relative;
        .pinjaman-left-info-box{
          margin-top: 24px;
        }
        .pinjaman-bottom-box{
          img{
            width: 192px;
            position: absolute;
            bottom: 0;
            right: 38px;
          }
        }
      }
    }
  }


  .feedback-wrap{
    width: 100%;
    min-height: 440px;
    background: #FBFFFF;
    padding: 60px 0 30px 0;
    box-sizing: border-box;
    .feedback-card{
      max-width: 1324px;
      min-height: 378px;
      margin: 0 auto;
      background: url("../assets/v3/home/bubble.png") no-repeat;
      background-size: 100% 100%;
      margin-bottom: 20px;
      position: relative;
      img{
        max-width: 560px;
        position: absolute;
        right: 5px;
        top: 20px;
      }
      .feedback-carousel{
        width: 100%;
        padding: 40px 100px;
        box-sizing: border-box;
        font-size: 23px;
        .feedback-text{
          line-height: 33px;
        }
        .feedback-prople{
          font-weight: bold;
          color: #2AA669;
          margin-top: 30px;
        }
      }
    }
    .feedback-bottom{
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 44px;
      img{
        width: 54px;
        height: 54px;
        margin-right: 20px;
      }
    }
  }

  .lender-wrap{
    width: 100%;
    min-height: 250px;
    background: #FBFFFF;
    padding: 60px 0 60px 0;
    box-sizing: border-box;
    .lender-card{
      max-width: 1324px;
      min-height: 250px;
      margin: 0 auto;
      background: url("../assets/v3/home/lender-bg.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 170px 0 120px;
      box-sizing: border-box;
      .rp-img{
        width: 130px;
        height: 155px;
      }
      .lender-center{
        text-align: center;
        font-weight: 600;
        img{
          width: 88px;
          height: 27px;
          margin-bottom: 4px;
        }
        .sm-ios{
          margin-left: 15px;
        }
        .center-t{
          font-size: 40px;
        }
        .center-c{
          font-size: 22px;
          margin-bottom: 6px;
        }
        .center-b{
          display: flex;
          align-items: center;
          font-size: 14px;
          div{
            padding: 0 18px;
            border-right: 1px solid #000;
          }
          div:last-child{
            border: none;
          }
        }
      }
      .lender-right{
        font-size: 14px;
        font-weight: 600;
        color: #28543F;
        .lender-btn{
          width: 247px;
          height: 60px;
          background: #28543F;
          border-radius: 43px;
          text-align: center;
          line-height: 60px;
          cursor: pointer;
          font-size: 20px;
          font-weight: 600;
          margin-top: 40px;
          color: #fff;
        }
      }
    }
  }

  .m-lender-wrap{
    width: 100%;
    min-height: 200px;
    background: url("../assets/v3/home/m-lender-bg.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    .m-lender-card{
      padding: 22px 10% !important;
      .m-lender-title{
        font-size: 22px;
        font-weight: 600;
      }
      .m-lender-tip{
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .m-lender-center{
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          margin-bottom: 10px;
          div{
            padding: 0 18px;
            border-right: 1px solid #000;
          }
          div:last-child{
            border: none;
          }
      }
      .m-lender-bottom{
        font-size: 12px;
        margin-bottom: 10px;
      }
      .m-lender-btn{
        width: 80%;
        height: 40px;
        line-height: 40px;
        background: #28543F;
        border-radius: 19px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        margin: 0 auto;
        color: #fff;
      }
    }
  }

  .partner-wrap{
    width: 100%;
    min-height: 250px;
    background: #FBFFFF;
    text-align: center;
    .partner-tip{
      font-size: 44px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .partner{
      width: 100%;
      background: #fff;
      padding: 40px 0;
      text-align: center;
      img{
        max-width: 1000px;
        height: 49px;
      }
    }
  }
  .auth-wrap {
    width: 100%;
    min-height: 250px;
    background: #fbffff;
    .auth-card {
      max-width: 100%;
      min-height: 250px;
      border-top: 1px solid #f6f6f6;
      margin: 0 auto;
      text-align: center;
      font-size: 18px;
      color: #1d1d1d;
      font-weight: bold;
      padding: 46px 0 100px 0;
      box-sizing: border-box;
      img {
        margin: 20px 0 14px 0;
      }
      .image-wrapper {
        background: rgb(246, 246, 246);
        border-radius: 20px;
        padding: 10px 20px;
        display: inline-block;
        margin: 20px 0 14px 0;
        img {
          max-width: 2500px;
          margin: 0 5px;
        }
      }
      .img-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        margin: 20px 0 14px 0;
        background: rgb(246, 246, 246);
        border-radius: 20px;
        padding: 10px 20px;
        img {
          // max-width: 90px;
          margin: 0 5px;
        }
      }
      .kan {
        max-width: 250px;
        background: rgb(246, 246, 246);
        border-radius: 20px;
        padding: 10px 20px;
      }
      .cek-text {
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

  .m-auth-wrap {
    width: 100%;
    padding: 10px 22px 20px 22px;
    box-sizing: border-box;
    .m-auth-item {
      width: 100%;
      padding: 16px 20px;
      background: #f6f6f6;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #28543f;
      margin-bottom: 10px;
      .m-auth-left {
        .m-auth-title {
          font-size: 12px;
          font-weight: bold;
          margin-bottom: 6px;
        }
        .m-auth-text {
          font-size: 12px;
          cursor: pointer;
          display: none;
        }
      }
      .m-auth-right {
        .m-img-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          img {
            width: auto;
            height: 40px;
            max-width: 115px;
          }
        }
        img {
          width: 100%;
        }
        .m-kan {
          max-width: 220px;
        }
        .m-ojk {
          max-width: 140px;
        }
      }
    }
  }
}
</style>
